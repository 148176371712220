

import Vue from "vue";
import { BusinessInfo, } from "@/types/data";
import { Dictionary } from "lodash";
import axios from "axios";
import _ from "lodash";
import { CreateTenantErrorResponse, CreateTenantPayload } from "@/types/responses";
import { CreateTenantErrorTypes } from "@/types/enums";
// import { ActionTypes } from "@/enums/vuex";
export default Vue.extend({
    name: "CompanyInput",
    props: {
        errorResponse: {
            type:  Object as () => CreateTenantErrorResponse || null,
			required: false
        }
    },
    data(): {
            loading: boolean
            companyNameModel: null | BusinessInfo
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            items: any[]
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            orgItems: any[]
            orgNumber: string
            search: null
            companyNameInput: string | null
            orgNumberInput: null | BusinessInfo | string
            isLoading: boolean // brønnøysund name search loading indicator
            isLoadingOrg: boolean // brønnøysund org number loading indicator
            orgSearch: null
            //error-overrides
            orgNumError: boolean
            orgNumErrorMsg: string[]
            orgNameError: boolean
            orgNameErrorMsg: string[]            

    } {
        return {
            loading: false,
            companyNameModel: null,
            items: [],
            orgItems: [],
            search: null,
            companyNameInput: null,
            orgNumberInput: "",
            isLoading: false,
            isLoadingOrg: false,
            orgNumber: "",
            orgSearch: null,
            orgNumError: false,
            orgNumErrorMsg: [],
            orgNameError: false,
            orgNameErrorMsg: []
        }
    },

    watch: {
        errorResponse: {
            handler(errors: CreateTenantErrorResponse | null){
                if(errors){
                    if(Object.hasOwn(errors, CreateTenantErrorTypes.businessRegistryId)){
                        this.orgNumError = true
                        this.orgNumErrorMsg = errors[CreateTenantErrorTypes.businessRegistryId as keyof typeof errors] ?? []
                    }
                    if(Object.hasOwn(errors, CreateTenantErrorTypes.companyName)){
                        this.orgNameError = true
                        this.orgNameErrorMsg = errors[CreateTenantErrorTypes.companyName as keyof typeof errors] ?? []
                    }
                }
            }
        },
        async search (val) {

        this.companyNameInput = val

        this.isLoading = true
        const { data } = await axios.get(`https://data.brreg.no/enhetsregisteret/api/enheter?navn=${val}`)
        try{
            const dataItems: BusinessInfo[] = _.map(data._embedded.enheter, itm => {
            let address

            try{
                address = _.join(itm.forretningsadresse.adresse, (', '))
            }catch(e){
                address = ""
            }
                
            let city
            try{
                city = `${itm.forretningsadresse.postnummer} - ${itm.forretningsadresse.poststed}`
            }catch (e) {
                city = ""
            }
                    
            const item: BusinessInfo = {name: itm.navn, orgNumber: itm.organisasjonsnummer, address: address, city: city} 
            return item
            })
            this.isLoading = false
            this.items = dataItems
            this.orgItems = dataItems
        } catch (e){
            this.isLoading = false
            this.items = []
            this.orgItems = []
        }


    },

    async orgSearch(val){

        if (val !== null && val.length === 9){

            this.orgNumber = val
            this.isLoadingOrg = true
            const { data } = await axios.get(`https://data.brreg.no/enhetsregisteret/api/enheter?organisasjonsnummer=${val}`)
            try{
                const dataItems: BusinessInfo[] = _.map(data._embedded.enheter, itm => {
                    let address

                    try{
                        address = _.join(itm.forretningsadresse.adresse, (', '))
                    }catch(e){
                        address = ""
                    }
                        
                    let city
                    try{
                        city = `${itm.forretningsadresse.postnummer} - ${itm.forretningsadresse.poststed}`
                    }catch (e) {
                        city = ""
                    } 
                    
                    const item: BusinessInfo = {name: itm.navn, orgNumber: itm.organisasjonsnummer, address: address, city: city}
                    return item
                })
                this.isLoadingOrg = false
                this.orgItems = dataItems
                this.items = dataItems
            }catch(e){
                this.isLoadingOrg = false
                this.items = []
                this.orgItems = []
            }
        }
    }
},
    methods: {
        customValidationRule(orgnumber: string): boolean{
            if(orgnumber.length === 0){
                return true
            }
            else return false
        },
        syncOrgNumber(event: BusinessInfo){
            if(this.orgNameError){
                this.orgNameError = false
                this.orgNameErrorMsg = []
            }
            // Only set OrgNumber if orgnumber is provided 
            if(event !== null && event.orgNumber !== undefined){

                this.orgNumberInput = event
                this.orgNumber = event.orgNumber
                this.syncOrgName(event)
            }else{

                this.orgNumberInput = null
                this.orgNumber = ""
            }
            this.updateBusinessInfo()
        },
        syncOrgName(event: BusinessInfo | string){

            //Clear validation errors from server-response if field is updated
            if(this.orgNumError){
                this.orgNumError = false
                this.orgNumErrorMsg = []
            }
            if (typeof event === "string"){
                this.orgNumber = event
            }else{
                if(event !== null ){

                this.companyNameInput = event.name
                this.orgNumber = event.orgNumber
                this.companyNameModel = event
                } else{
                    this.orgNumber = ""
                    this.orgNumberInput = ""
                }
            }
            this.updateBusinessInfo()
        },
        clearCompanyName(){
            this.companyNameInput = null
            this.companyNameModel = null
            this.clearOrgNumber()
        },
        clearOrgNumber(){
            this.orgNumber = ""
            this.orgNumberInput = ""
            this.updateBusinessInfo()
        },
        isCompanyFormValid(): boolean{
            if(this.companyNameInput !== null && this.companyNameInput.length > 0){
                if(this.orgNumber !== null){
                    //If OrgNumber input is not empty, it must be only numbers
                    return /^[0-9]*$/.test(this.orgNumber)
                }
                return true
            }
            return false
        },
        async updateBusinessInfo(){
            const tenantData: CreateTenantPayload = await this.$store.state.context.createTenantFormData
            tenantData.businessRegistryId = this.orgNumber ?? ""
            tenantData.companyName = this.companyNameInput ?? ""

            if(this.isCompanyFormValid()){
                await this.$store.dispatch("context/setCreateTenantFormData", tenantData)
                this.isLoading = false
                this.isLoadingOrg = false
            }

        }
        
    }
});
